<template>
  <div>
    <appheader index="3"></appheader>
    <div class="list">
      <div class="list_title">账号管理</div>
      <div class="search">
        <div class="demo-input-suffix">
          <div class="account">
            <span class="title">账号：</span>
            <el-input placeholder="输入账号查询" prefix-icon="el-icon-search" v-model="userCode"> </el-input>
          </div>
          <div class="account">
            <span class="title">姓名：</span>
            <el-input placeholder="输入姓名查询" prefix-icon="el-icon-search" v-model="realName"> </el-input>
          </div>
        </div>
        <el-button @click="search">搜 索</el-button>
      </div>
      <div class="btn">
        <div @click="operation('新增')">新增账号</div>
        <div @click="operation('重置')">重置密码</div>
        <div @click="operation('修改')">修 改</div>
        <div @click="operation('解锁')">解 锁</div>
        <div @click="operation('激活')">激 活</div>
        <div @click="operation('停用')">停 用</div>
        <div @click="operation('删除')">删 除</div>
      </div>
      <div class="tab">
        <el-table
          :data="tableData"
          v-loading="loading"
          element-loading-text="正在加载数据"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0,.1)"
          ref="multipleTable"
          border
          @selection-change="handleSelectionChange"
          style="width: 100%"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column prop="userCode" label="账号"> </el-table-column>
          <el-table-column prop="realName" label="姓名"> </el-table-column>
          <el-table-column prop="userType" label="角色">
            <template slot-scope="scope">{{ scope.row.userType | userType }}</template>
          </el-table-column>
          <el-table-column prop="lock" label="状态">
            <template slot-scope="scope">{{ scope.row.status | status }}</template>
          </el-table-column>
          <el-table-column prop="lock" label="密码解锁">
            <template slot-scope="scope">{{ scope.row.lock | lock }}</template>
          </el-table-column>
        </el-table>
      </div>
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          background
          :page-sizes="[20, 40, 60, 100]"
          :page-size="pageSize"
          layout="total, prev, pager, next, sizes, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>
<script>
import Appheader from '../moudel/Appheader.vue';
import bottom from '../bady_bottom/bottom';
export default {
  name: 'Accountmanagement',
  data() {
    return {
      realName: '',
      userCode: '',
      tableData: [],
      tableData: [],
      titleType: '',
      loading: false,
      pageNum: 1,
      pageSize: 20,
      total: 0,
      networkCoding: [],
      branches: '',
    };
  },
  components: {
    Appheader,
    bottom,
  },
  // 修改
  // filters: {
  //   type (value) {
  //     var type;
  //     if (value == "P") {
  //       type = "公司";
  //       return type;
  //     } else if (value == "B") {
  //       type = "机构";
  //       return type;
  //     } else if (value == "S") {
  //       type = "网点";
  //       return type;
  //     }
  //   },
  // },
  mounted() {
    this.getList();
  },
  // 过滤器
  filters: {
    userType(value) {
      var userType;
      if (value == '1') {
        userType = '老板';
        return userType;
      } else if (value == '2') {
        userType = '店长';
        return userType;
      } else if (value == '3') {
        userType = '展厅';
        return userType;
      }
    },
    status(value) {
      var status;
      if (value == '1') {
        status = '激活';
        return status;
      } else if (value == '2') {
        status = '停用';
        return status;
      }
    },
    lock(value) {
      var lock;
      if (value == '1') {
        lock = '正常';
        return lock;
      } else if (value == '2') {
        lock = '锁定';
        return lock;
      }
    },
  },
  methods: {
    // 搜索
    search() {
      this.pageNum = 1;
      this.getList();
    },
    operation(text) {
      if (text == '新增') {
        this.$router.push({ path: '/newaccount', query: { text: '新增账号' } });
      } else {
        if (!this.multipleSelection || this.multipleSelection.length == 0) {
          this.$message({
            message: '请先勾选账号！',
            type: 'warning',
            duration: 1500,
          });
        } else {
          let ids = [];
          this.multipleSelection.forEach((ele) => {
            ids.push(ele.id);
          });
          this.loading = true;
          if (text == '解锁') {
            var url = `/system/user/unlock`;
            this.$axios({
              url: url,
              method: 'post',
              headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json',
              },
              data: JSON.stringify({
                ids: ids,
                lock: 1,
              }),
            }).then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  message: '解锁成功',
                  type: 'success',
                  duration: 1500,
                });
                this.pageNum = 1;
                this.getList();
              } else {
                this.$message({
                  message: '解锁失败！',
                  type: 'warning',
                  duration: 1500,
                });
              }
            });
          } else if (text == '激活' || text == '停用') {
            this.$confirm(text == '激活' ? '确定激活该账号嘛？' : '确定停用该账号嘛？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
              .then(() => {
                var url = `/system/user/activate`;
                this.$axios({
                  url: url,
                  method: 'post',
                  headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/json',
                  },
                  data: JSON.stringify({
                    ids: ids,
                    status: text == '激活' ? 1 : 2,
                  }),
                }).then((res) => {
                  if (res.data.code == 200) {
                    this.$message({
                      message: text == '激活' ? '激活成功' : '停用成功',
                      type: 'success',
                      duration: 1500,
                    });
                    this.pageNum = 1;
                    this.getList();
                  } else {
                    this.$message({
                      message: text == '激活' ? '激活失败！' : '停用失败！',
                      type: 'warning',
                      duration: 1500,
                    });
                  }
                });
              })
              .catch(() => {});
          } else if (text == '删除') {
            this.$confirm('确定删除该账号嘛？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning',
            })
              .then(() => {
                var url = `/system/user//delete`;
                this.$axios({
                  url: url,
                  method: 'post',
                  headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Content-Type': 'application/json',
                  },
                  data: JSON.stringify({
                    ids: ids,
                    delFlag: 3,
                  }),
                }).then((res) => {
                  if (res.data.code == 200) {
                    this.$message({
                      message: '删除成功',
                      type: 'success',
                      duration: 1500,
                    });
                    this.pageNum = 1;
                    this.getList();
                  } else {
                    this.$message({
                      message: '删除失败！',
                      type: 'warning',
                      duration: 1500,
                    });
                  }
                });
              })
              .catch(() => {});
          } else if (text == '修改') {
            if (this.multipleSelection.length > 1) {
              this.$message({
                message: '修改用户不能多选！',
                type: 'warning',
                duration: 1500,
              });
            } else {
              let id = '';
              id = this.multipleSelection[0].id;
              this.$router.push({ path: '/newaccount', query: { text: '修改账号', ids: id } });
            }
          } else if (text == '重置') {
            var url = `/system/user/restPassword`;
            this.$axios({
              url: url,
              method: 'post',
              headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json',
              },
              data: JSON.stringify({
                ids: ids,
              }),
            }).then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  message: '重置密码成功',
                  type: 'success',
                  duration: 1500,
                });
                this.pageNum = 1;
                this.getList();
              } else {
                this.$message({
                  message: '重置密码失败！',
                  type: 'warning',
                  duration: 1500,
                });
              }
            });
          }
          this.loading = false;
        }
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    getList() {
      this.tableData = [];
      this.loading = true;
      var url = `/system/user/list`;
      this.$axios({
        url: url,
        method: 'post',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          realName: this.realName,
          userCode: this.userCode,
        }),
      }).then((res) => {
        this.total = res.data.total;
        if (res.data.code == 0) {
          res.data.rows.forEach((element) => {
            this.tableData.push(element);
          });
          this.loading = false;
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
  },
};
</script>
<style scoped lang="scss">
.list {
  width: 1500px;
  margin: 0 auto;
  min-height: 550px;
}
.list_title {
  color: #000;
  font-size: 20px;
  font-weight: bold;
  margin: 28px 0;
}
.search {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.demo-input-suffix {
  display: flex;
  align-content: center;
  align-items: center;
}
.account:last-child {
  margin-left: 20px;
}
.account {
  display: flex;
  align-content: center;
  align-items: center;
  .title {
    width: 60px;
  }
}
.el-button:hover {
  border-radius: none;
  border: 1px solid #ec9368;
}
.el-button {
  width: 150px;
  padding: 0;
  color: #fff;
  background: #ec9368;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  border-radius: none;
}
.tab {
  margin: 20px 0 0 0;
}
.btn {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
  div {
    width: 100px;
    height: 40px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    background: #ec9368;
    margin-left: 20px;
    cursor: pointer;
  }
}
.page {
  text-align: center;
  margin: 40px 0;
}
/deep/ .el-table th,
.el-table tr {
  // background: #ec9368;
  // color: #fff;
}
.el-pagination.is-background .btn-next.disabled,
.el-pagination.is-background .btn-next:disabled,
.el-pagination.is-background .btn-prev.disabled,
.el-pagination.is-background .btn-prev:disabled,
.el-pagination.is-background .el-pager li.disabled {
  color: #c0c4cc !important;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #ec9368 !important;
}
.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
  color: #fff !important;
}
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li:hover {
  color: #ec9368 !important;
}
/deep/ .el-pager li.active + li {
  border: none !important;
}
/deep/ .el-table_1_column_1 {
  background: #fff !important;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #ec9368 !important;
  border-color: #ec9368 !important;
}
/deep/ .el-checkbox__inner::after {
  height: 18px !important;
  left: 12px !important;
  top: 3px !important;
  width: 7px !important;
}
/deep/ .el-checkbox__inner {
  width: 32px !important;
  height: 32px !important;
}
/deep/ .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
  top: 15px !important;
}
/deep/ .el-checkbox__inner:hover {
  border-color: #ec9368 !important;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #ec9368 !important;
  border-color: #ec9368 !important;
}
</style>
<style>
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #ec9368 !important;
  border-color: #ec9368 !important;
}
</style>
